import { Injectable } from "@angular/core";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { take } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private toastr: ToastrService) {

    }
    success(message?: string | undefined, title?: string | undefined, override?: Partial<IndividualConfig<any>>, clickCallback?: Function) {
        let noti = this.toastr.success(message, title, override);
        if(clickCallback){
            noti.onTap.pipe(take(1)).subscribe(() => clickCallback());
        }
    }
    error(message?: string | undefined, title?: string | undefined, override?: Partial<IndividualConfig<any>>, clickCallback?: Function) {
        let noti = this.toastr.error(message, title, override);
        if(clickCallback){
            noti.onTap.pipe(take(1)).subscribe(() => clickCallback());
        }
    }
    info(message?: string | undefined, title?: string | undefined, override?: Partial<IndividualConfig<any>>, clickCallback?: Function) {
        let noti = this.toastr.info(message, title, override);
        if(clickCallback){
            noti.onTap.pipe(take(1)).subscribe(() => clickCallback());
        }
    }
    warning(message?: string | undefined, title?: string | undefined, override?: Partial<IndividualConfig<any>>, clickCallback?: Function) {
        let noti = this.toastr.warning(message, title, override);
        if(clickCallback){
            noti.onTap.pipe(take(1)).subscribe(() => clickCallback());
        }
    }
}